<template>
  <a-card title="Import form excel">
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">

      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
      </div>
    </div>
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      files: null,
    }
  },
}
</script>

<style>

</style>
